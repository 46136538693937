var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-text',[_c('p',[_vm._v(" Bu listede her bir BB-sakin kaydını ayrı birer satır olarak listeleyebilirsiniz. ")]),_c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',_vm._b({attrs:{"title":"Tüm Bağımsız Bölüm-Sakinler","icon":"mdi-fireplace-off","hide-edit":"","edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)}}},'rs-table-title-bar',_vm.titleBarAttrs,false)):_vm._e()]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'incomes.residents.show',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.cluster_name",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{
            name: 'definitions.clusters.show',
            params: { id: item.cluster_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.resident_name",fn:function({ item, value }){return [(value)?_c('router-link',{attrs:{"to":{
            name: 'definitions.residents.show',
            params: { id: item.resident_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()]}},{key:"item.door_number",fn:function({ item, value }){return [(value && _vm.can('see-house'))?_c('router-link',{attrs:{"to":{
            name: 'definitions.houses.show',
            params: { id: item.house_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):[_vm._v(" "+_vm._s(value)+" ")]]}},{key:"item.balance",fn:function({ value }){return [_c('rs-table-cell-balance',{attrs:{"colored":"","value":value}})]}},{key:"item.is_active",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.email",fn:function({ value }){return [_c('rs-table-cell-email',{attrs:{"value":value}})]}},{key:"item.phone",fn:function({ value }){return [_c('rs-table-cell-phone',{attrs:{"value":value}})]}},{key:"item.phone_2",fn:function({ value }){return [_c('rs-table-cell-phone',{attrs:{"value":value}})]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }