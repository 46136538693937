<template>
  <v-card :loading="isLoading">
    <v-card-text>
      <p>
        Bu listede her bir BB-sakin kaydını ayrı birer satır olarak
        listeleyebilirsiniz.
      </p>
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Tüm Bağımsız Bölüm-Sakinler"
            icon="mdi-fireplace-off"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            hide-edit
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'incomes.residents.show',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.cluster_name="{ item, value }">
          <router-link
            :to="{
              name: 'definitions.clusters.show',
              params: { id: item.cluster_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.resident_name="{ item, value }">
          <router-link
            v-if="value"
            :to="{
              name: 'definitions.residents.show',
              params: { id: item.resident_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.door_number="{ item, value }">
          <router-link
            v-if="value && can('see-house')"
            :to="{
              name: 'definitions.houses.show',
              params: { id: item.house_id },
            }"
          >
            {{ value }}
          </router-link>
          <template v-else>
            {{ value }}
          </template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.email="{ value }">
          <rs-table-cell-email :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.phone="{ value }">
          <rs-table-cell-phone :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.phone_2="{ value }">
          <rs-table-cell-phone :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions, isPage } from "@/view/mixins";

export default {
  name: "HouseUserList",
  mixins: [filtersToURL, hasExport, hasPermissions, isPage],
  computed: {
    ...mapGetters(["clusterId", "houseUserTypeList"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
    clusterId: {
      handler() {
        this.loadList();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.generateExportColumns(this.headers);
      this.loadURLParams();
    });
  },
  data() {
    return {
      pageMeta: {
        title: "Tüm BB-Sakinler",
      },
      options: {
        sortBy: ["resident_name"],
      },
      titleBarAttrs: {
        exportUrl: () => `house-users`,
        exportParams: this.getParams,
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("see-house-income"),
        },
        {
          text: "TYA Adı",
          value: "cluster_name",
          searchable: "text",
          width: "175px",
        },
        {
          text: "Blok",
          value: "block",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: "BB No",
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: "Adı Soyadı",
          value: "resident_name",
          searchable: "text",
          width: "155px",
        },
        {
          text: this.$t("labels.identity_no"),
          value: "identity_number",
          searchable: "text",
        },
        {
          text: "Oturum Şekli",
          value: "house_user_type_name",
          searchable: "multiselect",
          searchValue: "house_user_type_ids",
          options: () => this.houseUserTypeList,
          width: "100px",
        },
        {
          text: "BB Tipi",
          value: "house_type_name",
          searchable: "text",
        },
        {
          text: "Araçlar",
          value: "vehicles",
          searchable: "text",
          width: "150px",
        },
        {
          text: "Bakiye",
          value: "balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Telefon",
          value: "phone",
          searchable: "text",
        },
        {
          text: "Telefon 2",
          value: "phone_2",
          searchable: "text",
        },
        {
          text: "E-posta",
          value: "email",
          searchable: "text",
        },
        {
          text: "Durum",
          value: "is_active",
          searchable: "isActive",
        },
      ],
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.clusterId) {
        params.cluster_id = [this.clusterId];
      }

      return params;
    },
    loadList() {
      this.selectedItems = [];
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = this.getParams();

      this.$api
        .query("house-users", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
